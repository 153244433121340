import axios from "axios";
import PortalCooperadoUtils from '../utilities/PortalCooperadoUtils';

const API_BASE_URL = PortalCooperadoUtils.apiCooperado;
const RESOURCE_V1 = `/v1/imposto-renda`;

class ImpostoRendaService {

    getImpostoRenda(matricula, ano) {
        return axios.get(API_BASE_URL + RESOURCE_V1 + '/' + matricula + '/' + ano);
    }

    getAnosDisponiveis(page = 0, size = 10, sort = 'anoDisponivel') {
        return axios.get(API_BASE_URL + RESOURCE_V1 + '/ano-disponivel', {
            params: {
                page: page,
                size: size,
                sort: sort
            }
        });
    }
}

export default new ImpostoRendaService();