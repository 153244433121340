<template>
    <InformeRendimentos :matricula="currentUser.matricula" />
</template>

<script lang="js">
    
    import InformeRendimentos from '../../components/consulta/InformeRendimentos.vue'

    export  default {
        computed: {
			currentUser() {
				return this.$store.state.auth.user;
			}
		},
        components: { InformeRendimentos }
    }

</script>