<template>
    <div class="col-12">
        <div class="card">
            <h5>Informe de Rendimento</h5>
            <div class="grid align-items-center">
                <AutoComplete 
                    placeholder="Ano"
                    :minLength="2"
                    :dropdown="true"
                    v-model="anoSelecionado" 
                    :suggestions="anosFiltrados" 
                    @complete="buscarAnos($event)"
                    field="anoDisponivel"
                    class="col-12 md:col-3"
                />
                
                <Button label="Buscar" icon="pi pi-search" @click="consultar" class="col-12 md:col-1"></Button>
            </div>

            <hr />
            
            <InformeRendimentosDetalhado 
                v-if="visualizarIR"
                :cabecalho="cabecalho"
                :cooperado="cooperado"
                :retencao="retencao"
                :rateioSobra="rateioSobra"
                :valorReceita="valorReceita"
                :valorDespesas="valorDespesas"
                :informacoes="informacoes"
            />

            <Message severity="info" v-if="!visualizarIR" :closable="false">Por favor, informar o <strong>ano</strong> para visualizar o informe de rendimentos.</Message>
        </div>
    </div>

</template>

<script lang="js">
    import ImpostoRendaService from '../../service/ImpostoRendaService'
    import InformeRendimentosDetalhado from './InformeRendimentosDetalhado';

    export default {
        name: 'InformeRendimentos', 
        props: {
            matricula: {
                type: Number,
                required: true
            }
        },
        data() {
            return {
                anoSelecionado: null,
                anosFiltrados: [],
                anos: [],

                visualizarIR: false,
                cabecalho: {
                    titulo: "",
                    endereco: "",
                    cnpj: "",
                    demonstrativo: ""
                },
                cooperado: {
                    nome: "",
                    matricula: "",
                    cnpjCpf: "",
                    filial: ""
                },
                retencao: [],
                rateioSobra: "",
                valorReceita: [],
                valorDespesas: [],
                informacoes: []
            }
        },

        mounted() {
            ImpostoRendaService.getAnosDisponiveis(0, 5, 'anoDisponivel').then(({ data }) => {
                this.anos = data.content;
            });
        },

        components: { InformeRendimentosDetalhado },
        
        methods: {
            consultar() {
                this.cabecalho.titulo = "";
                this.cabecalho.endereco = "";
                this.cabecalho.cnpj = "";
                this.cabecalho.demonstrativo = "";
                
                this.cooperado.nome = "";
                this.cooperado.matricula = "";
                this.cooperado.cnpjCpf = "";
                this.cooperado.filial = "";
                
                this.retencao = [];
                this.rateioSobra = "";
                this.valorReceita = [];
                this.valorDespesas = [];
                this.informacoes = [];
                
                let ano = this.anoSelecionado;
                if(typeof this.anoSelecionado == 'string') {
                    ano = this.anos.find((item) => {
                        return item.anoDisponivel.toString() == this.anoSelecionado;
                    });
                }
                
                // Verificar se o ano informado é valido
                this.visualizarIR = ano != undefined && ano.anoDisponivel != undefined;
                if(!this.visualizarIR) return;

                ImpostoRendaService.getImpostoRenda(this.matricula, ano.anoDisponivel).then(({ data }) => {
                    if(data) {
                        let subtitulo = "";

                        for(let item of data) {
                            if(item.id === 1 && item.destaque === " " && item.nivelIdentacao === 0) {
                                this.cabecalho.titulo = item.descricao;
                            }
                            if(item.id === 1 && item.destaque === "H" && item.nivelIdentacao === 0) {
                                this.cabecalho.titulo = item.descricao;
                            }
                            else if(item.id === 2 && item.destaque === "H" && item.nivelIdentacao === 0) {
                                this.cabecalho.endereco = item.descricao;
                            }
                            else if(item.id === 3 && item.destaque === "H" && item.nivelIdentacao === 0) {
                                this.cabecalho.cnpj = item.descricao;
                            }
                            else if(item.id === 5 && item.destaque === "H" && item.nivelIdentacao === 0) {
                                this.cabecalho.demonstrativo = item.descricao;
                            }
                            else if(item.id === 7 && item.destaque === "H" && item.nivelIdentacao === 0) {
                                this.cooperado.nome = item.descricao.replace("Cooperado: ", "");
                            }
                            else if(item.id === 8 && item.destaque === "H" && item.nivelIdentacao === 0) {
                                this.cooperado.matricula = item.descricao.replace("Número Controle: ", "");
                            }
                            else if(item.id === 9 && item.destaque === "H" && item.nivelIdentacao === 0) {
                                this.cooperado.cnpjCpf = item.descricao.replace("CPF/CNPJ: ", "");
                            }
                            else if(item.id === 10 && item.destaque === "H" && item.nivelIdentacao === 0) {
                                this.cooperado.filial = item.descricao.replace("Filial: ", "");
                            }
                            else if(item.destaque === "F" && item.nivelIdentacao === 0) {
                                if(item.descricao) {
                                    this.informacoes.push(item.descricao);
                                }
                            }
                            else if(item.descricao === "4 - VALOR DESPESAS" && item.destaque === "T" && item.nivelIdentacao === 0) {
                                subtitulo = item.descricao;
                            }
                            else if(subtitulo === "4 - VALOR DESPESAS") {
                                if(item.descricao) {
                                    this.valorDespesas.push({
                                        isSubnivel: item.nivelIdentacao > 1,
                                        descricao: item.descricao,
                                        valor: item.valor
                                    });
                                }
                            }
                            else if(item.descricao === "3 - VALOR RECEITA" && item.destaque === "T" && item.nivelIdentacao === 0) {
                                subtitulo = item.descricao;
                            }
                            else if(subtitulo === "3 - VALOR RECEITA") {
                                if(item.descricao) {
                                    this.valorReceita.push({
                                        isSubnivel: item.nivelIdentacao > 1,
                                        descricao: item.descricao,
                                        valor: item.valor
                                    });
                                }
                            }
                            else if(item.descricao === "2 - RATEIO DE SOBRAS" && item.destaque === "T" && item.nivelIdentacao === 0) {
                                this.rateioSobra = item.valor;
                            }
                            else if(item.descricao === "1 - RETENÇÃO" && item.destaque === "T" && item.nivelIdentacao === 0) {
                                subtitulo = item.descricao;
                            }
                            else if(subtitulo === "1 - RETENÇÃO" && item.nivelIdentacao === 1) {
                                if(item.descricao) {
                                    this.retencao.push({
                                        descricao: item.descricao,
                                        valor: item.valor
                                    });
                                }
                            }
                        }
                    }
                });
            },

            buscarAnos(event) {
                setTimeout(() => {
                    if (!event.query.trim().length) {
                        this.anosFiltrados = [...this.anos];
                    }
                    else {
                        this.anosFiltrados = this.anos.filter((item) => {
                            return item.anoDisponivel.toString().startsWith(event.query.toLowerCase());
                        });
                    }
                }, 250);
            }
        }    
    }
</script>