<template>
    <div v-if="cabecalho.titulo!=='Demonstrativo não está disponível.'">
        <div class="grid">
            <div class="col-12 md:col-2">
                <DownloadPDF
                    :cabecalho="cabecalho"
                    :cooperado="cooperado"
                    :retencao="retencao"
                    :rateioSobra="rateioSobra"
                    :valorReceita="valorReceita"
                    :valorDespesas="valorDespesas"
                    :informacoes="informacoes"
                />
            </div>
        </div>

        <div class="box-ir" ref="ir">
            <div class="grid align-items-center">
                <div class="col-12 md:col-4" style="text-align: center; width: 20%;">
                    <img src="../../../public/logo_coop_integrada.png" style="width: 270px;"/>
                </div>
                <div class="col-12 md:col-8 align-center">
                    <h3>{{ cabecalho.titulo }}</h3>
                    <p>
                        {{ cabecalho.endereco }}
                        <br />
                        {{ cabecalho.cnpj }}
                    </p>
                </div>
            </div>

            <h5 style="text-align: center;">{{ cabecalho.demonstrativo }}</h5>

            <div class="grid align-items-center">
                <div class="col-12 md:col-8">
                    <strong>Cooperado:&nbsp;</strong>{{ cooperado.nome }}
                </div>
                <div class="col-12 md:col-4">
                    <strong>CPF/CNPJ:&nbsp;</strong>{{ cooperado.cnpjCpf }}
                </div>
                <div class="col-12 md:col-4">
                    <strong>Número Controle:&nbsp;</strong>{{ cooperado.matricula }}
                </div>
                <div class="col-12 md:col-8">
                    <strong>Filial:&nbsp;</strong>{{ cooperado.filial }}
                </div>
            </div>

            <br />

            <table style="width: 100%;">
                <tr>
                    <td colspan="2" class="subtitulo">
                        <strong>1 - RETENÇÃO</strong>
                    </td>
                </tr>
                <tr v-for="item in retencao" :key="item.descricao">
                    <td class="linha descricao">{{ item.descricao }}</td>
                    <td class="linha valor">{{ item.valor }}</td>
                </tr>

                <tr>
                    <td class="subtitulo descricao">
                        <strong>2 - RATEIO DE SOBRAS</strong>
                    </td>
                    <td class="subtitulo valor">{{ rateioSobra }}</td>
                </tr>

                <tr>
                    <td  class="subtitulo">
                        <strong>3 - VALOR RECEITA</strong>
                    </td>
                </tr>
                
                <tr v-for="item in valorReceita" :key="item.descricao">
                    <td class="linha descricao"><span v-if="item.isSubnivel">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>{{ item.descricao }}</td>
                    <td class="linha valor">{{ item.valor }}</td>
                </tr>

                <tr>
                    <td  class="subtitulo">
                        <strong>4 - VALOR DESPESAS</strong>
                    </td>
                </tr>

                <tr v-for="item in valorDespesas" :key="item.descricao">
                    <td class="linha descricao"><span v-if="item.isSubnivel">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>{{ item.descricao }}</td>
                    <td class="linha valor">{{ item.valor }}</td>
                </tr>

                <tr>
                    <td class="informacoes">
                        <p v-for="informacao in informacoes" :key="informacao">
                            <strong>{{ informacao }}</strong>
                        </p>
                    </td>
                </tr>
            </table>
        </div>
    </div>
    <div v-else>
        <Message severity="info" :closable="false">O informe de rendimento, para o ano selecionado, não está disponível. Em caso de dúvidas entrar em contato com sua Regional.</Message>
    </div>
</template>

<script lang="js">
    import DownloadPDF from './InformeRendimentosPDF';

    export default {
        name: 'InformeRendimentosDetalhado', 
        props: {
            cabecalho: {
                type: Object,
                required: true
            },
            cooperado: {
                type: Object,
                required: true
            },
            retencao: {
                type: Array,
                required: true
            },
            rateioSobra: {
                type: String,
                required: true
            },
            valorReceita: {
                type: Array,
                required: true
            },
            valorDespesas: {
                type: Array,
                required: true
            },
            informacoes: {
                type: Array,
                required: true
            }
        },
        components: { DownloadPDF } 
    }
</script>

<style scoped>
    .box-ir {
        border: 1px solid #CCC; 
        padding: 15px;
    }

    .align-center {
        text-align: center;
    }

    .subtitulo {
        padding-top: 20px;
    }

    .linha {
        padding-top: 10px;
        border-bottom: 1px dotted #CCC;
    }

    .descricao {
        width: 70%;
    }

    .valor {
        width: 30%;
        text-align: right;
    }

    .informacoes {
        padding-top: 20px; 
        font-size: 12px;
    }
</style>